@import url('https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700,900');
/* UTILS */
/* -------------------------------------------------------------- */
.p5w {
  padding-left: .357em;
  padding-right: 0.357em;
}
.p5h {
  padding-top: .357em;
  padding-bottom: 0.357em;
}
.m5w {
  margin-left: .357em;
  margin-right: 0.357em;
}
.m5h {
  margin-top: .357em;
  margin-bottom: 0.357em;
}
.p10w {
  padding-left: .714em;
  padding-right: 0.714em;
}
.p10h {
  padding-top: .714em;
  padding-bottom: 0.714em;
}
.m10w {
  margin-left: .714em;
  margin-right: 0.714em;
}
.m10h {
  margin-top: .714em;
  margin-bottom: 0.714em;
}
.p20w {
  padding-left: 1.429em;
  padding-right: 1.429em;
}
.p20h {
  padding-top: 1.429em;
  padding-bottom: 1.429em;
}
.p30w {
  padding-left: 1.875em;
  padding-right: 1.875em;
}
.p30h {
  padding-top: 1.875em;
  padding-bottom: 1.875em;
}
.p50w {
  padding-left: 3.125em;
  padding-right: 3.125em;
}
.p50h {
  padding-top: 3.125em;
  padding-bottom: 3.125em;
}
.m20w {
  margin-left: 1.429em;
  margin-right: 1.429em;
}
.m20h {
  margin-top: 1.429em;
  margin-bottom: 1.429em;
}
.m30w {
  margin-left: 1.875em;
  margin-right: 1.875em;
}
.m30h {
  margin-top: 1.875em;
  margin-bottom: 1.875em;
}
.m50h {
  margin-top: 3.125em;
  margin-bottom: 3.125em;
}
.no-p {
  padding: 0 !important;
}
.no-pr {
  padding-right: 0 !important;
}
.no-pl {
  padding-left: 0 !important;
}
.no-m {
  margin: 0 !important;
}
/* LAYOUT */
/* -------------------------------------------------------------- */
body {
  overflow-x: hidden;
  max-width: 100vw;
}
h1 {
  font-weight: 600;
  color: #06c;
  font-size: 32px;
  margin-bottom: 6px;
}
h2 {
  color: #06c;
  font-weight: 600;
}
h3 {
  color: #06c;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}
h4 {
  color: #004c99;
  text-transform: uppercase;
  font-weight: 600;
}
.bg-green {
  background-color: #2BCC9E;
  border: none;
}
.bg-green:hover {
  background-color: #65dfbc;
}
.vertical {
  font-family: 'Comfortaa', cursive !important;
  color: #444;
  background: #fff;
}
.vertical .ant-layout {
  background: #fff;
  transition: all 0.5s;
}
.vertical .logo {
  border-bottom: 1px solid #eee;
  margin: 16px;
  margin-bottom: 0;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 20px;
}
.vertical .profile-menu {
  padding-left: 24px;
  border-bottom: 1px solid #eee;
}
.vertical .trigger {
  font-size: 18px;
  line-height: 20px;
  padding: 0 16px;
  cursor: pointer;
  transition: color .3s;
}
.vertical .trigger:hover {
  color: #ccc;
}
.vertical .ant-layout-sider-dark {
  background: #fff;
}
.vertical .ant-layout-sider-dark .ant-menu-inline.ant-menu-sub {
  background: #fff;
  box-shadow: none;
}
.vertical .ant-menu-dark {
  background: #fff;
  color: #444;
}
.vertical .ant-menu-dark .ant-menu-item,
.vertical .ant-menu-dark .ant-menu-item-group-title {
  color: #444;
}
.vertical .ant-menu-dark .ant-menu-item:hover,
.vertical .ant-menu-dark .ant-menu-item-group-title:hover {
  color: #3da5f4;
}
.vertical .ant-menu-dark .ant-menu-item-selected {
  background: transparent;
  font-weight: 700;
  color: #3da5f4;
}
.vertical .ant-menu-dark .ant-menu-submenu-title {
  color: #444;
}
.vertical .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.vertical .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background: #444;
}
.vertical .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #3da5f4;
}
.vertical .ant-menu-dark .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.vertical .ant-menu-dark .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
  background: #444;
}
.vertical .mainContent {
  font-family: 'Comfortaa', cursive;
  padding: 24px;
  background: #fafafa;
  min-height: 280px;
  border-radius: 10px;
  border: 5px solid #fff;
  position: relative;
  margin: 20px 70px 20px 260px;
  box-shadow: 0 0 16px rgba(41, 42, 51, 0.06), 0 6px 20px rgba(41, 42, 51, 0.02);
}
.vertical .mainContent h3 {
  text-transform: none;
  color: #444;
}
.vertical .mainContent .btnDrawer {
  float: right;
  z-index: 10;
}
.ant-table-body{
  overflow: auto;
}


.scrollbar::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	background-color: #AAAAAA;
}
