
/*@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600');*/

.italia-it-button{display:inline-block;position:relative;padding:0;color:#FFF;font-family:"Titillium Web",HelveticaNeue,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;font-weight:600;line-height:1em;text-decoration:none;border:0;text-align:center;cursor:pointer;overflow:hidden}
.italia-it-button-icon{display:block; float: left;}
.italia-it-button-icon{margin:0 -.4em 0 0;padding:0.6em .8em .5em;border-right:rgba(255,255,255,0.1) 0.1em solid}
.italia-it-button-text{padding:.95em 1em .85em 1em;font-size:1.15em;text-align:center;display:block;margin-right: 34px;}
/*svg{width:1.8em;height:1.8em;fill:#fff}*/
.italia-it-block{display:block}
.italia-it-button-size-auto{font-size:15px;width:100%}.italia-it-button-size-auto > span img{width:29px;height:29px;border:0}
.italia-it-button-size-s{font-size:10px;width:150px}.italia-it-button-size-s > span img{width:19px;height:19px;border:0}
.italia-it-button-size-m{font-size:15px;width:220px}.italia-it-button-size-m > span img{width:29px;height:29px;border:0}
.italia-it-button-size-l{font-size:20px;width:280px}.italia-it-button-size-l > span img{width:38px;height:38px;border:0}
.italia-it-button-size-xl{font-size:25px;width:340px}.italia-it-button-size-xl > span img{width:47px;height:47px;border:0}
.button-spid{background-color:#06C;color:#FFF}
.button-spid /*svg{fill:#FFF}*/
.button-spid:hover{background-color:#036;color:#FFF}
.button-spid:active{background-color:#83BEED;color:#036}
.spid-idp-button{position:absolute;z-index:1039;display:none}
.spid-idp-button .spid-idp-button-menu,
.spid-idp-button .spid-idp-button-panel{list-style:none;background:white;border:solid 1px #ddd;box-shadow:0 0 5px rgba(0,0,0,0.2);overflow:visible;padding:0;margin:0}
#spid-idp-button-small-get,
#spid-idp-button-medium-get,
#spid-idp-button-small-post,
#spid-idp-button-medium-post{width:230px}
#spid-idp-button-large-get,
#spid-idp-button-large-post{width:270px}
#spid-idp-button-xlarge-get,
#spid-idp-button-xlarge-post{width:330px}
.spid-idp-button
.spid-idp-button-panel{padding:10px}
.spid-idp-button.spid-idp-button-tip{margin-top:8px}
.spid-idp-button.spid-idp-button-tip:before{position:absolute;top:-6px;left:9px;content:"";border-left:7px solid transparent;border-right:7px solid transparent;border-bottom:7px solid #ddd;display:inline-block}
.spid-idp-button.spid-idp-button-tip:after{position:absolute;top:-5px;left:10px;content:"";border-left:6px solid transparent;border-right:6px solid transparent;border-bottom:6px solid white;display:inline-block}
.spid-idp-button.spid-idp-button-tip.spid-idp-button-anchor-right:before{left:auto;right:9px}
.spid-idp-button.spid-idp-button-tip.spid-idp-button-anchor-right:after{left:auto;right:10px}
.spid-idp-button.spid-idp-button-scroll
.spid-idp-button-menu,.spid-idp-button.spid-idp-button-scroll
.spid-idp-button-panel{max-height:180px;overflow:auto}
.spid-idp-button .spid-idp-button-menu li{list-style:none;padding:0 0;margin:0;line-height:18px}
.spid-idp-button .spid-idp-button-menu li > a,.spid-idp-button .spid-idp-button-menu label{display:block;font-family:"Titillium Web",HelveticaNeue,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;font-weight:600;font-size:.9em;color:#06C;text-decoration:underline;line-height:18px;padding-top:5px;white-space:nowrap;border-bottom:1px solid #ddd}
.spid-idp-button .spid-idp-button-menu li > a:hover,.spid-idp-button .spid-idp-button-menu label:hover{color:#036;cursor:pointer;background-color:#F0F0F0}.spid-idp-button
.spid-idp-button-menu li > a img{height:45px;padding:10px 0 10px 10px;border:0}.idp-button-idp-logo{font-size:100%;height:10%;width:100%;border:0;border-bottom:1px solid #CCC;background-color:#FFF;padding:15px;text-align:left;cursor:pointer}.idp-button-idp-logo:hover{background-color:#F0F0F0}.idp-button-idp-logo img{height:25px;vertical-align:middle;cursor:pointer}
.spid-top-menu-space-10 > a img{margin-top:10px}
.spid-top-menu-space-20 > a img{margin-top:20px}
.spid-top-menu-line{border-top:5px solid #000}
.spid-idp-support-link > a{padding:5px 0 10px 10px}
.spid-sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}
.container-spid-button{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
}