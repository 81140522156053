@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700,900);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:300,400,600,700,900);
body {
  margin: 0;
  padding: 0;
  height: auto;
  min-height: 100%;
  font-family: sans-serif;
}

/* UTILS */
/* -------------------------------------------------------------- */
.p5w {
  padding-left: .357em;
  padding-right: 0.357em;
}
.p5h {
  padding-top: .357em;
  padding-bottom: 0.357em;
}
.m5w {
  margin-left: .357em;
  margin-right: 0.357em;
}
.m5h {
  margin-top: .357em;
  margin-bottom: 0.357em;
}
.p10w {
  padding-left: .714em;
  padding-right: 0.714em;
}
.p10h {
  padding-top: .714em;
  padding-bottom: 0.714em;
}
.m10w {
  margin-left: .714em;
  margin-right: 0.714em;
}
.m10h {
  margin-top: .714em;
  margin-bottom: 0.714em;
}
.p20w {
  padding-left: 1.429em;
  padding-right: 1.429em;
}
.p20h {
  padding-top: 1.429em;
  padding-bottom: 1.429em;
}
.p30w {
  padding-left: 1.875em;
  padding-right: 1.875em;
}
.p30h {
  padding-top: 1.875em;
  padding-bottom: 1.875em;
}
.p50w {
  padding-left: 3.125em;
  padding-right: 3.125em;
}
.p50h {
  padding-top: 3.125em;
  padding-bottom: 3.125em;
}
.m20w {
  margin-left: 1.429em;
  margin-right: 1.429em;
}
.m20h {
  margin-top: 1.429em;
  margin-bottom: 1.429em;
}
.m30w {
  margin-left: 1.875em;
  margin-right: 1.875em;
}
.m30h {
  margin-top: 1.875em;
  margin-bottom: 1.875em;
}
.m50h {
  margin-top: 3.125em;
  margin-bottom: 3.125em;
}
.no-p {
  padding: 0 !important;
}
.no-pr {
  padding-right: 0 !important;
}
.no-pl {
  padding-left: 0 !important;
}
.no-m {
  margin: 0 !important;
}
/* LAYOUT */
/* -------------------------------------------------------------- */
body {
  overflow-x: hidden;
  max-width: 100vw;
}
h1 {
  font-weight: 600;
  color: #06c;
  font-size: 32px;
  margin-bottom: 6px;
}
h2 {
  color: #06c;
  font-weight: 600;
}
h3 {
  color: #06c;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}
h4 {
  color: #004c99;
  text-transform: uppercase;
  font-weight: 600;
}
.bg-green {
  background-color: #2BCC9E;
  border: none;
}
.bg-green:hover {
  background-color: #65dfbc;
}
.vertical {
  font-family: 'Comfortaa', cursive !important;
  color: #444;
  background: #fff;
}
.vertical .ant-layout {
  background: #fff;
  transition: all 0.5s;
}
.vertical .logo {
  border-bottom: 1px solid #eee;
  margin: 16px;
  margin-bottom: 0;
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 20px;
}
.vertical .profile-menu {
  padding-left: 24px;
  border-bottom: 1px solid #eee;
}
.vertical .trigger {
  font-size: 18px;
  line-height: 20px;
  padding: 0 16px;
  cursor: pointer;
  transition: color .3s;
}
.vertical .trigger:hover {
  color: #ccc;
}
.vertical .ant-layout-sider-dark {
  background: #fff;
}
.vertical .ant-layout-sider-dark .ant-menu-inline.ant-menu-sub {
  background: #fff;
  box-shadow: none;
}
.vertical .ant-menu-dark {
  background: #fff;
  color: #444;
}
.vertical .ant-menu-dark .ant-menu-item,
.vertical .ant-menu-dark .ant-menu-item-group-title {
  color: #444;
}
.vertical .ant-menu-dark .ant-menu-item:hover,
.vertical .ant-menu-dark .ant-menu-item-group-title:hover {
  color: #3da5f4;
}
.vertical .ant-menu-dark .ant-menu-item-selected {
  background: transparent;
  font-weight: 700;
  color: #3da5f4;
}
.vertical .ant-menu-dark .ant-menu-submenu-title {
  color: #444;
}
.vertical .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.vertical .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background: #444;
}
.vertical .ant-menu-dark .ant-menu-submenu-title:hover {
  color: #3da5f4;
}
.vertical .ant-menu-dark .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.vertical .ant-menu-dark .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
  background: #444;
}
.vertical .mainContent {
  font-family: 'Comfortaa', cursive;
  padding: 24px;
  background: #fafafa;
  min-height: 280px;
  border-radius: 10px;
  border: 5px solid #fff;
  position: relative;
  margin: 20px 70px 20px 260px;
  box-shadow: 0 0 16px rgba(41, 42, 51, 0.06), 0 6px 20px rgba(41, 42, 51, 0.02);
}
.vertical .mainContent h3 {
  text-transform: none;
  color: #444;
}
.vertical .mainContent .btnDrawer {
  float: right;
  z-index: 10;
}
.ant-table-body{
  overflow: auto;
}


.scrollbar::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb
{
	background-color: #AAAAAA;
}

.component-exception-exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.component-exception-exception .component-exception-imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.component-exception-exception .component-exception-imgBlock::before,
.component-exception-exception .component-exception-imgBlock::after {
  content: ' ';
  display: table;
}
.component-exception-exception .component-exception-imgBlock::after {
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
}
.component-exception-exception .component-exception-imgEle {
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.component-exception-exception .component-exception-content {
  flex: auto;
}
.component-exception-exception .component-exception-content h1 {
  margin-bottom: 24px;
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
}
.component-exception-exception .component-exception-content .component-exception-desc {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
}
.component-exception-exception .component-exception-content .component-exception-actions button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .component-exception-exception .component-exception-imgBlock {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .component-exception-exception {
    display: block;
    text-align: center;
  }
  .component-exception-exception .component-exception-imgBlock {
    margin: 0 auto 24px;
    padding-right: 0;
  }
}
@media screen and (max-width: 480px) {
  .component-exception-exception .component-exception-imgBlock {
    margin-bottom: -24px;
    overflow: hidden;
  }
}

.sidebar {
  box-shadow: 0 0 8px #c7c3c7;
  z-index: 2;
  background-color: #fff;
}
.sidebar .ant-layout-sider-zero-width-trigger {
  top: 0;
  box-shadow: 4px 1px 5px #ddd;
}
.sidebar .sidebar-logo {
  height: 64px;
  margin: 0;
  padding: 16px;
  background-color: #0066cc;
}
.sidebar .sidebar-logo i {
  display: inline-block;
  padding: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 20px;
  background: #fff;
  position: relative;
  top: -3px;
}
.sidebar .sidebar-logo img {
  width: 23px;
  margin: 5px 0 0 -5px;
}
.sidebar .sidebar-logo span {
  font-size: 20px;
  position: absolute;
  top: 20px;
  margin-left: 10px;
  letter-spacing: 2px;
  color: #fff;
}
.sidebar .ant-menu-light {
  background: #fff;
  color: #444;
}
.sidebar .ant-menu-light.ant-menu-inline {
  border-right: none;
}
.sidebar .ant-menu-light .ant-menu-item,
.sidebar .ant-menu-light .ant-menu-item-group-title {
  color: #444;
}
.sidebar .ant-menu-light .ant-menu-item:hover,
.sidebar .ant-menu-light .ant-menu-item-group-title:hover {
  color: #3da5f4;
}
.sidebar .ant-menu-light .ant-menu-item-selected {
  background: transparent;
  font-weight: 700;
  color: #3da5f4;
}
.sidebar .ant-menu-light .ant-menu-submenu-title {
  color: #444;
}
.sidebar .ant-menu-light .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.sidebar .ant-menu-light .ant-menu-submenu-title .ant-menu-submenu-arrow:after {
  background: #444;
}
.sidebar .ant-menu-light .ant-menu-submenu-title:hover {
  color: #3da5f4;
}
.sidebar .ant-menu-light .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.sidebar .ant-menu-light .ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after {
  background: #444;
}
.sidebar .ant-menu-submenu-title-no-ellipsis .ant-menu-submenu-title {
  text-overflow: clip;
}

/* UTILS */
/* -------------------------------------------------------------- */
.p2w {
  padding-left: 0.157em;
  padding-right: 0.157em;
}
.m2w {
  margin-left: 0.157em;
  margin-right: 0.157em;
}
.p5w {
  padding-left: 0.357em;
  padding-right: 0.357em;
}
.p5h {
  padding-top: 0.357em;
  padding-bottom: 0.357em;
}
.m5w {
  margin-left: 0.357em;
  margin-right: 0.357em;
}
.m5h {
  margin-top: 0.357em;
  margin-bottom: 0.357em;
}
.p10w {
  padding-left: 0.714em;
  padding-right: 0.714em;
}
.p10h {
  padding-top: 0.714em;
  padding-bottom: 0.714em;
}
.m10w {
  margin-left: 0.714em;
  margin-right: 0.714em;
}
.m10h {
  margin-top: 0.714em;
  margin-bottom: 0.714em;
}
.p20w {
  padding-left: 1.429em;
  padding-right: 1.429em;
}
.p20h {
  padding-top: 1.429em;
  padding-bottom: 1.429em;
}
.p30w {
  padding-left: 1.875em;
  padding-right: 1.875em;
}
.p30h {
  padding-top: 1.875em;
  padding-bottom: 1.875em;
}
.p50w {
  padding-left: 3.125em;
  padding-right: 3.125em;
}
.p50h {
  padding-top: 3.125em;
  padding-bottom: 3.125em;
}
.m20w {
  margin-left: 1.429em;
  margin-right: 1.429em;
}
.m20h {
  margin-top: 1.429em;
  margin-bottom: 1.429em;
}
.m30w {
  margin-left: 1.875em;
  margin-right: 1.875em;
}
.m30h {
  margin-top: 1.875em;
  margin-bottom: 1.875em;
}
.m50h {
  margin-top: 3.125em;
  margin-bottom: 3.125em;
}
.no-p {
  padding: 0 !important;
}
.no-pr {
  padding-right: 0 !important;
}
.no-pl {
  padding-left: 0 !important;
}
.no-m {
  margin: 0 !important;
}
/* LAYOUT */
/* -------------------------------------------------------------- */
body {
  overflow-x: hidden;
  max-width: 100vw;
}
h1 {
  font-weight: 600;
  color: #0066CC;
  font-size: 36px;
  margin-bottom: 6px;
}
h2 {
  color: #0066CC;
  font-weight: 600;
  font-size: 24px;
}
h3 {
  color: #0066CC;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}
h4 {
  color: #0066CC;
  text-transform: uppercase;
  font-weight: 600;
}
.color-green {
  color: #31AA47;
}
.color-orange {
  color: #e67200;
}
.color-red {
  color: #ff0000;
}
.bg-green {
  background-color: #31AA47;
  border: none;
  color: #fff;
}
.bg-green:hover {
  background-color: #58cf6e;
  color: #fff;
}
.bg-blue {
  background-color: #0066CC;
  border: none;
  color: #fff;
}
.bg-blue:hover {
  background-color: #1a8cff;
  color: #fff;
}
.bg-orange {
  background-color: #e67200;
  border: none;
  color: #fff;
}
.bg-orange:hover {
  background-color: #ff9833;
  color: #fff;
}
.spinnerLoading {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.header {
  background-color: #0066CC;
  min-height: 220px;
}
.header.ant-layout-header {
  border-bottom: 1px solid #cccccc;
  padding: 0;
}
.header.ant-layout-header .headerTop {
  background-color: #003183;
  padding-left: 10%;
}
.header.ant-layout-header .headerTop a {
  color: #fff;
  font-size: 18px;
}
.header.ant-layout-header .headerBottom {
  padding: 40px 10px 0 10px;
}
.header.ant-layout-header .headerBottom .headerLogo {
  text-align: center;
  border-right: 1px solid #fff;
}
.header.ant-layout-header .headerBottom h1 {
  color: #fff;
  line-height: 38px;
  font-weight: 500;
}
.header.ant-layout-header .headerBottom h2 {
  color: #fff;
  font-weight: 500;
  line-height: 26px;
  font-size: 20px;
}
@media (max-width: 576px) {
  .header {
    min-height: 190px;
  }
  .header.ant-layout-header .headerBottom {
    padding: 20px;
  }
  .header.ant-layout-header .headerBottom .headerLogo {
    border: none;
  }
  .header.ant-layout-header .headerBottom .headerLogo img {
    max-width: 50px;
  }
  .header.ant-layout-header .headerBottom h1,
  .header.ant-layout-header .headerBottom h2 {
    padding-left: 10px;
    line-height: 20px;
    font-size: 16px;
  }
}

/* UTILS */
/* -------------------------------------------------------------- */
.p2w {
  padding-left: 0.157em;
  padding-right: 0.157em;
}
.m2w {
  margin-left: 0.157em;
  margin-right: 0.157em;
}
.p5w {
  padding-left: 0.357em;
  padding-right: 0.357em;
}
.p5h {
  padding-top: 0.357em;
  padding-bottom: 0.357em;
}
.m5w {
  margin-left: 0.357em;
  margin-right: 0.357em;
}
.m5h {
  margin-top: 0.357em;
  margin-bottom: 0.357em;
}
.p10w {
  padding-left: 0.714em;
  padding-right: 0.714em;
}
.p10h {
  padding-top: 0.714em;
  padding-bottom: 0.714em;
}
.m10w {
  margin-left: 0.714em;
  margin-right: 0.714em;
}
.m10h {
  margin-top: 0.714em;
  margin-bottom: 0.714em;
}
.p20w {
  padding-left: 1.429em;
  padding-right: 1.429em;
}
.p20h {
  padding-top: 1.429em;
  padding-bottom: 1.429em;
}
.p30w {
  padding-left: 1.875em;
  padding-right: 1.875em;
}
.p30h {
  padding-top: 1.875em;
  padding-bottom: 1.875em;
}
.p50w {
  padding-left: 3.125em;
  padding-right: 3.125em;
}
.p50h {
  padding-top: 3.125em;
  padding-bottom: 3.125em;
}
.m20w {
  margin-left: 1.429em;
  margin-right: 1.429em;
}
.m20h {
  margin-top: 1.429em;
  margin-bottom: 1.429em;
}
.m30w {
  margin-left: 1.875em;
  margin-right: 1.875em;
}
.m30h {
  margin-top: 1.875em;
  margin-bottom: 1.875em;
}
.m50h {
  margin-top: 3.125em;
  margin-bottom: 3.125em;
}
.no-p {
  padding: 0 !important;
}
.no-pr {
  padding-right: 0 !important;
}
.no-pl {
  padding-left: 0 !important;
}
.no-m {
  margin: 0 !important;
}
/* LAYOUT */
/* -------------------------------------------------------------- */
body {
  overflow-x: hidden;
  max-width: 100vw;
}
h1 {
  font-weight: 600;
  color: #0066CC;
  font-size: 36px;
  margin-bottom: 6px;
}
h2 {
  color: #0066CC;
  font-weight: 600;
  font-size: 24px;
}
h3 {
  color: #0066CC;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}
h4 {
  color: #0066CC;
  text-transform: uppercase;
  font-weight: 600;
}
.color-green {
  color: #31AA47;
}
.color-orange {
  color: #e67200;
}
.color-red {
  color: #ff0000;
}
.bg-green {
  background-color: #31AA47;
  border: none;
  color: #fff;
}
.bg-green:hover {
  background-color: #58cf6e;
  color: #fff;
}
.bg-blue {
  background-color: #0066CC;
  border: none;
  color: #fff;
}
.bg-blue:hover {
  background-color: #1a8cff;
  color: #fff;
}
.bg-orange {
  background-color: #e67200;
  border: none;
  color: #fff;
}
.bg-orange:hover {
  background-color: #ff9833;
  color: #fff;
}
.spinnerLoading {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.footer {
  z-index: 2;
  background-color: #00264d;
  min-height: 160px;
  color: #fff;
}
.footer.ant-layout-footer {
  border-bottom: 1px solid #cccccc;
  padding-top: 30px;
}
.footer.ant-layout-footer .logoPor {
  text-align: right;
}
.footer.ant-layout-footer .logoPor img {
  max-width: 100%;
}
.footer.ant-layout-footer h3 {
  color: #fff;
  line-height: 22px;
  text-transform: capitalize;
  font-weight: 300;
  font-size: 28px;
}
.footer.ant-layout-footer h4 {
  color: #fff;
  line-height: 20px;
  font-weight: 300;
}
.footer.ant-layout-footer .ant-menu {
  background: transparent;
  border: none;
}
.footer.ant-layout-footer .ant-menu .ant-menu-item {
  color: #65dcdf;
  font-weight: 500;
}
@media (max-width: 576px) {
  .footer.ant-layout-footer {
    border-bottom: 1px solid #cccccc;
    padding: 30px 0;
  }
  .footer.ant-layout-footer .logoPor {
    text-align: center;
    margin: 30px 0;
  }
  .footer.ant-layout-footer .logoPor img {
    max-width: 98%;
  }
  .footer.ant-layout-footer h4 {
    padding-right: 10px;
  }
  .footer.ant-layout-footer .copy {
    padding-top: 20px;
    text-align: center;
  }
  .footer.ant-layout-footer .ant-menu {
    text-align: center;
  }
  .footer.ant-layout-footer .ant-menu .ant-menu-item {
    color: #65dcdf;
    font-weight: 500;
  }
}
@media (max-width: 768px) {
  .footer {
    z-index: 2;
    background-color: #00264d;
    min-height: 160px;
  }
  .footer.ant-layout-footer {
    border-bottom: 1px solid #cccccc;
    padding: 30px 0;
  }
  .footer.ant-layout-footer .logoPor {
    text-align: center;
  }
  .footer.ant-layout-footer .logoPor img {
    max-width: 90%;
  }
}


/*@import url('https://fonts.googleapis.com/css?family=Titillium+Web:400,600');*/

.italia-it-button{display:inline-block;position:relative;padding:0;color:#FFF;font-family:"Titillium Web",HelveticaNeue,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;font-weight:600;line-height:1em;text-decoration:none;border:0;text-align:center;cursor:pointer;overflow:hidden}
.italia-it-button-icon{display:block; float: left;}
.italia-it-button-icon{margin:0 -.4em 0 0;padding:0.6em .8em .5em;border-right:rgba(255,255,255,0.1) 0.1em solid}
.italia-it-button-text{padding:.95em 1em .85em 1em;font-size:1.15em;text-align:center;display:block;margin-right: 34px;}
/*svg{width:1.8em;height:1.8em;fill:#fff}*/
.italia-it-block{display:block}
.italia-it-button-size-auto{font-size:15px;width:100%}.italia-it-button-size-auto > span img{width:29px;height:29px;border:0}
.italia-it-button-size-s{font-size:10px;width:150px}.italia-it-button-size-s > span img{width:19px;height:19px;border:0}
.italia-it-button-size-m{font-size:15px;width:220px}.italia-it-button-size-m > span img{width:29px;height:29px;border:0}
.italia-it-button-size-l{font-size:20px;width:280px}.italia-it-button-size-l > span img{width:38px;height:38px;border:0}
.italia-it-button-size-xl{font-size:25px;width:340px}.italia-it-button-size-xl > span img{width:47px;height:47px;border:0}
.button-spid{background-color:#06C;color:#FFF}
.button-spid /*svg{fill:#FFF}*/
.button-spid:hover{background-color:#036;color:#FFF}
.button-spid:active{background-color:#83BEED;color:#036}
.spid-idp-button{position:absolute;z-index:1039;display:none}
.spid-idp-button .spid-idp-button-menu,
.spid-idp-button .spid-idp-button-panel{list-style:none;background:white;border:solid 1px #ddd;box-shadow:0 0 5px rgba(0,0,0,0.2);overflow:visible;padding:0;margin:0}
#spid-idp-button-small-get,
#spid-idp-button-medium-get,
#spid-idp-button-small-post,
#spid-idp-button-medium-post{width:230px}
#spid-idp-button-large-get,
#spid-idp-button-large-post{width:270px}
#spid-idp-button-xlarge-get,
#spid-idp-button-xlarge-post{width:330px}
.spid-idp-button
.spid-idp-button-panel{padding:10px}
.spid-idp-button.spid-idp-button-tip{margin-top:8px}
.spid-idp-button.spid-idp-button-tip:before{position:absolute;top:-6px;left:9px;content:"";border-left:7px solid transparent;border-right:7px solid transparent;border-bottom:7px solid #ddd;display:inline-block}
.spid-idp-button.spid-idp-button-tip:after{position:absolute;top:-5px;left:10px;content:"";border-left:6px solid transparent;border-right:6px solid transparent;border-bottom:6px solid white;display:inline-block}
.spid-idp-button.spid-idp-button-tip.spid-idp-button-anchor-right:before{left:auto;right:9px}
.spid-idp-button.spid-idp-button-tip.spid-idp-button-anchor-right:after{left:auto;right:10px}
.spid-idp-button.spid-idp-button-scroll
.spid-idp-button-menu,.spid-idp-button.spid-idp-button-scroll
.spid-idp-button-panel{max-height:180px;overflow:auto}
.spid-idp-button .spid-idp-button-menu li{list-style:none;padding:0 0;margin:0;line-height:18px}
.spid-idp-button .spid-idp-button-menu li > a,.spid-idp-button .spid-idp-button-menu label{display:block;font-family:"Titillium Web",HelveticaNeue,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif;font-weight:600;font-size:.9em;color:#06C;text-decoration:underline;line-height:18px;padding-top:5px;white-space:nowrap;border-bottom:1px solid #ddd}
.spid-idp-button .spid-idp-button-menu li > a:hover,.spid-idp-button .spid-idp-button-menu label:hover{color:#036;cursor:pointer;background-color:#F0F0F0}.spid-idp-button
.spid-idp-button-menu li > a img{height:45px;padding:10px 0 10px 10px;border:0}.idp-button-idp-logo{font-size:100%;height:10%;width:100%;border:0;border-bottom:1px solid #CCC;background-color:#FFF;padding:15px;text-align:left;cursor:pointer}.idp-button-idp-logo:hover{background-color:#F0F0F0}.idp-button-idp-logo img{height:25px;vertical-align:middle;cursor:pointer}
.spid-top-menu-space-10 > a img{margin-top:10px}
.spid-top-menu-space-20 > a img{margin-top:20px}
.spid-top-menu-line{border-top:5px solid #000}
.spid-idp-support-link > a{padding:5px 0 10px 10px}
.spid-sr-only{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}
.container-spid-button{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
}
.card-with-image .ant-card-cover {
  padding-top: 80%;
  position: relative;
}
.card-with-image .ant-card-cover img {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  object-fit: cover;
}
.card-with-image .ant-card-cover div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  text-align: center;
  padding-top: 27px;
  color: #fff;
}
.card-with-image .ant-card-cover div i {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.card-with-image .ant-card-cover div.red {
  background-color: #ff0000;
}
.card-with-image .ant-card-cover div.green {
  background-color: #008000;
}
.card-with-image .ant-card-cover div.blue {
  background-color: #2e5a9a;
}

.steps-action {
  margin: 10px auto;
  width: 310px;
  text-align: center;
}
.steps-content {
  padding: 34px;
}
.text-green {
  color: #31AA47;
}
.complete.ant-anchor-wrapper {
  padding: 12px;
  background-color: transparent;
}
.complete.ant-anchor-wrapper .ant-anchor-link-title {
  text-transform: uppercase;
}
.gridView .gridViewCell {
  margin-bottom: 1px;
  padding: 20px 10px;
  border: 1px solid #ddd;
}
.gridView .gridViewCell.width25 {
  width: 25%;
}
.gridView .gridViewCell label {
  font-weight: 700;
}
#scheda-accreditamento #actions .ant-card {
  background: #f9f9f9;
}
#scheda-accreditamento #actions .ant-card .ant-card-body {
  padding: 15px;
}
#scheda-accreditamento .ant-tabs-bar {
  z-index: 999 !important;
}
#scheda-accreditamento .ant-tabs-bar .ant-tabs-tab {
  text-align: center;
  color: #808080;
  vertical-align: middle;
  text-transform: uppercase;
}
#scheda-accreditamento .ant-tabs-bar .ant-tabs-tab .anticon {
  visibility: hidden;
}
#scheda-accreditamento .ant-tabs-bar .ant-tabs-tab .checked {
  color: #31AA47;
}
#scheda-accreditamento .ant-tabs-bar .ant-tabs-tab .checked .anticon {
  visibility: visible;
}
#scheda-accreditamento .ant-tabs-bar .ant-tabs-tab .invalid {
  color: #d14a41;
}
#scheda-accreditamento .ant-tabs-bar .ant-tabs-tab-active {
  color: #06c;
  font-weight: 700;
}
#scheda-accreditamento .ant-tabs-content {
  padding: 20px 0;
}

.steps-action {
  margin: 10px auto;
  width: 310px;
  text-align: center;
}
.steps-content {
  padding: 34px;
}
.text-green {
  color: #31AA47;
}
.complete.ant-anchor-wrapper {
  padding: 12px;
  background-color: transparent;
}
.complete.ant-anchor-wrapper .ant-anchor-link-title {
  text-transform: uppercase;
}
.gridView .gridViewCell {
  margin-bottom: 1px;
  padding: 20px 10px;
  border: 1px solid #ddd;
}
.gridView .gridViewCell.width25 {
  width: 25%;
}
.gridView .gridViewCell label {
  font-weight: 700;
}
.scheda #actions .ant-card .ant-card-body {
  padding: 0px;
}
.scheda .ant-tabs-bar {
  z-index: 999 !important;
}
.scheda .ant-tabs-bar .ant-tabs-tab {
  text-align: center;
  color: #808080;
  vertical-align: middle;
  text-transform: uppercase;
}
.scheda .ant-tabs-bar .ant-tabs-tab .anticon {
  visibility: hidden;
}
.scheda .ant-tabs-bar .ant-tabs-tab .checked {
  color: #31AA47;
}
.scheda .ant-tabs-bar .ant-tabs-tab .checked .anticon {
  visibility: visible;
}
.scheda .ant-tabs-bar .ant-tabs-tab .invalid {
  color: #d14a41;
}
.scheda .ant-tabs-bar .ant-tabs-tab-active {
  color: #06c;
  font-weight: 700;
}
.scheda .ant-tabs-content {
  padding: 20px 0;
}
.btn-green {
  color: #31AA47;
  border-color: #31AA47;
}
.ico-blue {
  color: #06c;
  border-color: #06c;
}
.no-margin .ant-form-item {
  margin-bottom: 0px !important;
}
.record-not-valid {
  color: #d14a41;
  font-weight: 700;
}
.subtitle {
  color: #0066CC;
  text-transform: uppercase;
  font-size: small;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
  display: none;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  margin: 0px 4px !important;
  padding: 4px 0px !important;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-date .date-fab {
  display: none;
  position: absolute;
  bottom: 6px;
  right: 10px;
  z-index: 1;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover .date-fab {
  display: block;
}
.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  padding: 0 4px;
}
.ant-table-row-center {
  text-align: center !important;
}
.spin-overlay {
  position: absolute;
  left: calc(50% - 10px);
  top: calc(50% - 12px);
}

/* UTILS */
/* -------------------------------------------------------------- */
.p2w {
  padding-left: 0.157em;
  padding-right: 0.157em;
}
.m2w {
  margin-left: 0.157em;
  margin-right: 0.157em;
}
.p5w {
  padding-left: 0.357em;
  padding-right: 0.357em;
}
.p5h {
  padding-top: 0.357em;
  padding-bottom: 0.357em;
}
.m5w {
  margin-left: 0.357em;
  margin-right: 0.357em;
}
.m5h {
  margin-top: 0.357em;
  margin-bottom: 0.357em;
}
.p10w {
  padding-left: 0.714em;
  padding-right: 0.714em;
}
.p10h {
  padding-top: 0.714em;
  padding-bottom: 0.714em;
}
.m10w {
  margin-left: 0.714em;
  margin-right: 0.714em;
}
.m10h {
  margin-top: 0.714em;
  margin-bottom: 0.714em;
}
.p20w {
  padding-left: 1.429em;
  padding-right: 1.429em;
}
.p20h {
  padding-top: 1.429em;
  padding-bottom: 1.429em;
}
.p30w {
  padding-left: 1.875em;
  padding-right: 1.875em;
}
.p30h {
  padding-top: 1.875em;
  padding-bottom: 1.875em;
}
.p50w {
  padding-left: 3.125em;
  padding-right: 3.125em;
}
.p50h {
  padding-top: 3.125em;
  padding-bottom: 3.125em;
}
.m20w {
  margin-left: 1.429em;
  margin-right: 1.429em;
}
.m20h {
  margin-top: 1.429em;
  margin-bottom: 1.429em;
}
.m30w {
  margin-left: 1.875em;
  margin-right: 1.875em;
}
.m30h {
  margin-top: 1.875em;
  margin-bottom: 1.875em;
}
.m50h {
  margin-top: 3.125em;
  margin-bottom: 3.125em;
}
.no-p {
  padding: 0 !important;
}
.no-pr {
  padding-right: 0 !important;
}
.no-pl {
  padding-left: 0 !important;
}
.no-m {
  margin: 0 !important;
}
/* LAYOUT */
/* -------------------------------------------------------------- */
body {
  overflow-x: hidden;
  max-width: 100vw;
}
h1 {
  font-weight: 600;
  color: #0066CC;
  font-size: 36px;
  margin-bottom: 6px;
}
h2 {
  color: #0066CC;
  font-weight: 600;
  font-size: 24px;
}
h3 {
  color: #0066CC;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 20px;
}
h4 {
  color: #0066CC;
  text-transform: uppercase;
  font-weight: 600;
}
.color-green {
  color: #31AA47;
}
.color-orange {
  color: #e67200;
}
.color-red {
  color: #ff0000;
}
.bg-green {
  background-color: #31AA47;
  border: none;
  color: #fff;
}
.bg-green:hover {
  background-color: #58cf6e;
  color: #fff;
}
.bg-blue {
  background-color: #0066CC;
  border: none;
  color: #fff;
}
.bg-blue:hover {
  background-color: #1a8cff;
  color: #fff;
}
.bg-orange {
  background-color: #e67200;
  border: none;
  color: #fff;
}
.bg-orange:hover {
  background-color: #ff9833;
  color: #fff;
}
.spinnerLoading {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.main-layout {
  color: #444;
  background: #fff;
}
.main-layout .ant-layout {
  background: #fff;
  transition: all 0.5s;
}
.main-layout .logo {
  margin: 25px 10px;
  margin-bottom: 0;
  line-height: 30px;
  padding-bottom: 20px;
  font-size: 20px;
}
.main-layout .profile-menu {
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}
.main-layout .profile-menu ul {
  color: #0066CC;
}
.main-layout .trigger {
  font-size: 18px;
  line-height: 20px;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.3s;
}
.main-layout .trigger:hover {
  color: #ccc;
}
.main-layout .mainContent {
  padding: 55px 24px;
  background: #fafafa;
  min-height: 280px;
  position: relative;
}
.main-layout .mainContent h3 {
  text-transform: none;
  color: #444;
}
.main-layout .mainContent h3.sectionTitle {
  color: #0066CC;
  font-size: 28px;
  line-height: 0.8;
}
.main-layout .mainContent .btnDrawer {
  float: right;
  z-index: 10;
}
.ant-table-thead > tr > th {
  word-break: unset !important;
}
.ant-table-tbody > tr > td {
  word-break: unset !important;
}
.affix_element_for_display {
  display: none;
}
.ant-affix .affix_element_for_display {
  display: block;
  position: fixed;
  margin-top: 62px;
  background-color: #fff;
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 10px #d6d6d6;
}
.ant-row-flex.ant-flex-margin {
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 6px;
  padding-right: 6px;
}
.ant-row-flex.ant-flex-margin > .ant-col {
  padding: 6px;
}
.ant-form-item-label {
  white-space: normal;
}
.overlayer {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999999;
  opacity: 0.5;
}
.notifichePop .ant-popover-inner-content {
  padding: 0;
}
.notifichePop .ant-list-items .ant-list-item {
  padding: 5px 20px;
  cursor: pointer;
}
.notifichePop .ant-list-items .ant-list-item.not-read {
  background-color: #f9f9f9;
}
.notifichePop .ant-list-items .ant-list-item:hover {
  background-color: #eaeaea;
}
.notifichePop .popover-footer {
  padding: 2px 20px 5px;
  border-top: 1px solid #e8e8e8;
  background: #f9f9f9;
  border-radius: 5px;
}
.pallino {
  width: 10px;
  height: 10px;
  background: #000;
  display: inline-block;
  border-radius: 5px;
  margin-right: 8px;
}
.pallino.green {
  background: #31AA47;
}
.pallino.red {
  background: #ff0000;
}
.pallino.yellow {
  background: #feff61;
}
.pallino.orange {
  background: #e67200;
}
.pallino.grey {
  background: #d9d9d9;
}
.pallino.black {
  background: #000;
}
.pallino.invisible {
  background: transparent;
}
.ant-input-number {
  width: 100%;
}
.no-select-all .ant-table-column-title .ant-table-selection {
  display: none;
}
.ant-btn-link {
  padding: 0;
  height: auto;
}

